import { MESSAGE } from './constants'

export default function (user, persons, ready4start, imInfo, sendCommand, updateStat) {

    const ready = async () => {
        console.log('ready')
        persons.value.map(p => {
            if (p.id === user.value.id) {
                p.ready = true
            }
        })
        ready4start.value = true
        await notifyReadys()
    }

    const notifyReadys = async () => {        
        const readyPids = persons.value.filter(p => p.ready).map(p => p.id)
        console.log('notifyReadys -> ', readyPids)
        await updateStat({ ...imInfo.value, readyPids })
        sendCommand(MESSAGE.CMD_TYPE.READY_PIDS, readyPids.join(','))
    }

    return {
        ready,
        notifyReadys
    }
}