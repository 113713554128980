import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8e371598"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "content",
  class: "background"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(`markdown-body ${$options.landscape ? 'markdown-div' : 'markdown-div-p'} ${$props.full ? '' : 'half'}`),
    style: _normalizeStyle(`padding: ${$props.full ? '1rem' : '3rem'}`),
    innerHTML: $options.compiledMarkdown
  }, null, 14, _hoisted_2)]);
}