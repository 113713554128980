const marked = require('marked');
import taskService from '@/module/task';
// import 'github-markdown-css/github-markdown.css';
import './github.css';
// import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { debounce } from 'lodash';
export default {
  name: 'MarkDown',
  props: {
    taskId: {
      type: String,
      default: ''
    },
    full: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      content: ''
    };
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    tid() {
      return this.$route.query.id || this.taskId;
    },
    compiledMarkdown() {
      if (this.content) {
        const html = this.content.replace(/```markdown\s*([\s\S]*?)\s*```/g, '$1') + `<div style="text-align: right; color: #999;margin-top: 3em;">
                        <span style="font-size: 0.8em;">以上内容由AI生成,仅供参考和借鉴</span>
                    </div>`;
        return marked.parse(html);
      } else {
        return '';
      }
    }
  },
  watch: {
    tid(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        try {
          const tid = parseInt(newValue);
          this.initTask(tid);
        } catch (e) {
          console.log('参数不合法');
        }
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.tid) {
      this.initTask(this.tid);
    }
  },
  methods: {
    handleResize: debounce(function () {
      this.$store.commit('setDevice', window.innerWidth > window.innerHeight);
    }, 200),
    // 延迟200ms
    initTask(tid) {
      taskService.detail(tid).then(res => {
        switch (res.type) {
          case 'promotion':
            document.title = '会员简介';
            break;
          case 'emotion':
            document.title = '情感咨询报告';
            break;
          case 'pbz':
            document.title = '八字匹配报告';
            break;
          case 'activity_pbz':
            document.title = '命理匹配报告';
            break;
          case 'activity_survey':
            document.title = '价值观匹配报告';
            break;
          case 'survey':
            document.title = '调查问卷匹配报告';
            break;
          case 'predict':
            document.title = '姻缘预测报告';
            break;
          default:
            document.title = '分析报告';
            break;
        }
        this.content = res.content;
      });
    },
    async downloadImage() {
      try {
        const content = document.getElementById('content');
        const canvas = await html2canvas(content);
        const imgData = canvas.toDataURL('image/png');
        return imgData;
      } catch (error) {
        console.error('Error generating image:', error);
        return null;
      }
    },
    async getDownloadUrl() {
      try {
        const content = document.getElementById('content');
        const canvas = await html2canvas(content);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = document.title + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating image:', error);
      }
    }
  }
};