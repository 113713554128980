import { createStore } from 'vuex';
import hns from './hns'
import team from './team'
import activity from './activity'
import match from './match'

const store = createStore({
    state: {
        landscape: true, // 新添加的字段
        env: 'local'
    },
    getters: {
        landscape: state => state.landscape,
        env: state => state.env
    },
    mutations: {
        setDevice(state, value) {
            state.landscape = value;
        },
        setEnv(state, value) {
            state.env = value;
        }
    },
    modules: {
        hns,
        team,
        activity,
        match
    }
});

export default store;