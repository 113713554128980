import { mapActions } from 'vuex';
export default {
  name: 'TeamDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    success: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  data: () => {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      }
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(value)) {
        callback(new Error('请输入有效的手机号'));
      }
      callback();
    };
    return {
      loading: false,
      labelWidth: '100px',
      team: {
        admin_name: '',
        admin_phone: '',
        name: '',
        region: '',
        count: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入团队名称',
          trigger: 'blur'
        }],
        region: [{
          required: true,
          message: '请选择运营区域',
          trigger: 'change'
        }],
        count: [{
          required: true,
          message: '请选择使用账号数量',
          trigger: 'change'
        }],
        admin_name: [{
          required: true,
          message: '请输入管理员姓名',
          trigger: 'blur'
        }],
        admin_phone: [{
          required: true,
          message: '请输入管理员登录手机',
          trigger: 'blur'
        }, {
          validator: validatePhone,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    ...mapActions('team', ['addTeam']),
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.addTeam(this.team).then(res => {
            if (res) {
              this.$emit('success', {
                result: true
              });
            }
          }).catch(console.warn).finally(() => {
            this.loading = false;
            this.$emit('update:modelValue', false);
          });
        }
      });
    }
  }
};