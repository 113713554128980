import service from '@/module/team'

const state = () => ({
  team: {
    admin_name: '',
    admin_phone: '',
    name: '',
    region: '',
    count: ''
  },
  clue: {
    name: '',
    date: null,
    phone: ''
  }
});

const getters = {
  getTeam: state => {
    console.log("getTeam")
    return state.team
  },
  getClue: state => {
    console.log("getClue")
    return state.clue
  }
};

const actions = {
  addTeam({ commit }, payload) {
    console.log("submit team -> ", payload)
    return new Promise((resolve, reject) => {
      service.addTeam(payload).then(res => {
        if (res) {
          commit('setTeam', payload);
          resolve(res)
        }
      }).catch(reject)
    })
  },

  addClue({ commit }, payload) {
    console.log("submit clue -> ", payload)
    return new Promise((resolve, reject) => {
      service.addClue(payload).then(res => {
        if (res) {
          commit('setClue', payload);
          resolve(res)
        }
      }).catch(reject)
    })
  }
};

const mutations = {
  setTeam(state, team) {
    console.log("setTeam -> ", team)
    state.team = team;
  },
  setClue(state, clue) {
    console.log("setClue -> ", clue)
    state.clue = clue;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
