import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/home/HomePage.vue';
import ContactUs from '@/pages/contact/ContactUs.vue';
import MarkDown from '@/pages/md/MarkDown.vue';
import ActivityRoom from '@/pages/activity/ActivityRoom.vue';
import LiveRoom from '@/pages/living/LiveRoom.vue';
import MatchScreen from '@/pages/match/Main.vue';

const routes = [
    {
        path: '/live',
        name: 'LiveRoom',
        component: LiveRoom,
    },
    {
        path: '/activity',
        name: 'ActivityRoom',
        component: ActivityRoom,
    },
    {
        path: '/match',
        name: 'MatchScreen',
        component: MatchScreen,
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs,
    },
    {
        path: '/md',
        name: 'MarkDown',
        component: MarkDown,
    },
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    // 这里可以添加更多的路由
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;