import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ActivityRoom = _resolveComponent("ActivityRoom");
  const _component_LiveRoom = _resolveComponent("LiveRoom");
  const _component_MatchScreen = _resolveComponent("MatchScreen");
  const _component_CustomClue = _resolveComponent("CustomClue");
  const _component_OfficialPage = _resolveComponent("OfficialPage");
  return _ctx.initPage === 'activity' ? (_openBlock(), _createBlock(_component_ActivityRoom, {
    key: 0,
    aid: _ctx.aid,
    pid: _ctx.pid,
    hid: _ctx.hid
  }, null, 8, ["aid", "pid", "hid"])) : _ctx.initPage === 'live' ? (_openBlock(), _createBlock(_component_LiveRoom, {
    key: 1,
    aid: _ctx.aid,
    pid: _ctx.pid,
    hid: _ctx.hid,
    token: _ctx.token,
    appid: _ctx.appid
  }, null, 8, ["aid", "pid", "hid", "token", "appid"])) : _ctx.initPage === 'match' ? (_openBlock(), _createBlock(_component_MatchScreen, {
    key: 2,
    tid: _ctx.tid
  }, null, 8, ["tid"])) : _ctx.initPage === 'clue' ? (_openBlock(), _createBlock(_component_CustomClue, {
    key: 3,
    hid: _ctx.hid
  }, null, 8, ["hid"])) : (_openBlock(), _createBlock(_component_OfficialPage, {
    key: 4
  }));
}