import Base from './base.js'

class TaskService extends Base {
  constructor() {
    super('/task')
  }

  detail(id) {
    return new Promise((resolve, reject) => {
      this.nGet("/detail?tid="+id)
        .then(res => {
          resolve(res.result)
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
}

export default new TaskService()
