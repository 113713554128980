import { mapActions } from 'vuex';
export default {
  name: 'ClueDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    success: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  data: () => {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      }
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(value)) {
        callback(new Error('请输入有效的手机号'));
      }
      callback();
    };
    return {
      loading: false,
      labelWidth: '100px',
      clue: {
        name: '',
        date: null,
        phone: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入称呼',
          trigger: 'blur'
        }],
        date: [{
          required: true,
          message: '请选择预约时间',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          validator: validatePhone,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    ...mapActions('team', ['addClue']),
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.addClue(this.team).then(res => {
            if (res) {
              this.$emit('success', {
                result: true
              });
            }
          }).catch(console.warn).finally(() => {
            this.loading = false;
            this.$emit('update:modelValue', false);
          });
        }
      });
    }
  }
};