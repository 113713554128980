import service from '@/module/match'

const state = () => ({
    persons: [],
    lines: []
});

const getters = {
    getPersons: state => {
        return state.persons
    },
    getLines: state => {
        return state.lines
    }
};

const actions = {
    async matchList({ commit }, payload) {
        const data = await service.list(payload);
        if (data) {
            const { persons, lines } = data
            commit('setPersons', persons);
            commit('setLines', lines);
        }
    }
}

const mutations = {
    setPersons(state, persons) {
        state.persons = persons
    },
    setLines(state, lines) {
        state.lines = lines
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
