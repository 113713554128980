import Base from './base.js'

class TeamService extends Base {
  constructor() {
    super('/team')
  }

  async addTeam(team) {
    return new Promise((resolve, reject) => {
      this.nPost("/clue?type=team", team)
        .then(res => {
          console.log('fetch response -> ', res)
          resolve(res.result)
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async addClue(clue) {
    return new Promise((resolve, reject) => {
      this.nPost("/clue?type=clue", clue)
        .then(res => {
          console.log('fetch response -> ', res)
          resolve(res.result)
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

}

export default new TeamService()
