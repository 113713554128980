export default {
  name: 'MapComponent',
  data() {
    return {
      map: null
    };
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    }
  },
  watch: {
    landscape() {
      this.loadMapScript();
    }
  },
  methods: {
    loadMapScript() {
      this.landscape = window.innerWidth > window.innerHeight;
      const script = document.createElement('script');
      script.src = `https://webapi.amap.com/maps?v=1.4.15&key=cf17667b69f6bba57112782e9d37f72d&callback=initAMap`;
      script.async = true;
      document.head.appendChild(script);
      window.initAMap = this.initMap;
    },
    initMap() {
      const lng = 114.424286;
      const lat = 30.27967;
      const name = '跃来人工智能';
      this.map = new window.AMap.Map('mapContainer', {
        resizeEnable: true,
        //是否监控地图容器尺寸变化
        mapStyle: 'amap://styles/darkblue',
        center: [lng, lat],
        zoom: 10
      });
      // 添加标记
      const marker = new window.AMap.Marker({
        position: [lng, lat],
        // 标记位置
        map: this.map,
        title: name // 标记的标题
      });

      // 创建信息窗口
      const infoWindow = new window.AMap.InfoWindow({
        content: `
          <div style="font-size:${this.landscape ? 'auto' : '12px'}">武汉跃来人工智能科技有限公司
            <br>电话：13522714060</div>
            <a href="https://uri.amap.com/navigation?to=${lng},${lat},${name}
            &mode=car&policy=0&src=jsapi&coordinate=gaode&callnative=1" 
            target="_blank" style="font-size:${this.landscape ? 'auto' : '12px'}">前往地图</a>`,
        offset: new window.AMap.Pixel(0, -30)
      });

      // 绑定标记点击事件
      marker.on('click', () => {
        infoWindow.open(this.map, marker.getPosition());
      });

      // 默认打开信息窗口
      infoWindow.open(this.map, marker.getPosition());
    }
  },
  mounted() {
    this.loadMapScript();
  }
};