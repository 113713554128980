import Base from './base.js'

class MatchService extends Base {
  constructor() {
    super('/match')
  }

  async list(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/tlist", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async search(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/search", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async getMatchReports(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/reports", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
}

export default new MatchService()
