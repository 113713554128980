import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e5c007a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_el_dialog, {
    width: `${$options.landscape ? 500 : '90%'}`,
    modelValue: $options.visible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $options.visible = $event),
    title: "账号开通",
    "show-close": false
  }, {
    footer: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_button, {
      onClick: _cache[5] || (_cache[5] = $event => $options.visible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      class: "confirm",
      onClick: $options.submit
    }, {
      default: _withCtx(() => [_createTextVNode(" 确认 ")]),
      _: 1
    }, 8, ["onClick"])])), [[_directive_loading, _ctx.loading]])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "form",
      model: _ctx.team,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        prop: "name",
        label: "团队名称：",
        "label-width": _ctx.labelWidth
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.team.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.team.name = $event),
          placeholder: "请输入团队名称",
          autocomplete: "off"
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
        prop: "region",
        label: "运营地区：",
        "label-width": _ctx.labelWidth
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: _ctx.team.region,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.team.region = $event),
          placeholder: "点击选择所在区域"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_option, {
            label: "湖北省,武汉市",
            value: "湖北省,武汉市"
          }), _createVNode(_component_el_option, {
            label: "湖北省,荆州市",
            value: "湖北省,荆州市"
          }), _createVNode(_component_el_option, {
            label: "北京市,北京市",
            value: "北京市,北京市"
          }), _createVNode(_component_el_option, {
            label: "天津市,天津市",
            value: "天津市,天津市"
          }), _createVNode(_component_el_option, {
            label: "河北省,廊坊市",
            value: "河北省,廊坊市"
          }), _createVNode(_component_el_option, {
            label: "吉林省,长春市",
            value: "吉林省,长春市"
          }), _createVNode(_component_el_option, {
            label: "吉林省,吉林市",
            value: "吉林省,吉林市"
          }), _createVNode(_component_el_option, {
            label: "其它",
            value: "其它"
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
        prop: "count",
        label: "使用人数：",
        "label-width": _ctx.labelWidth
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: _ctx.team.count,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.team.count = $event),
          placeholder: "点击选择开通账号数量"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_option, {
            label: "1~5人",
            value: "5"
          }), _createVNode(_component_el_option, {
            label: "6~20人",
            value: "20"
          }), _createVNode(_component_el_option, {
            label: "21~50人",
            value: "50"
          }), _createVNode(_component_el_option, {
            label: "51~200人",
            value: "200"
          }), _createVNode(_component_el_option, {
            label: "200人以上",
            value: "200+"
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
        prop: "admin_name",
        label: "管理员：",
        "label-width": _ctx.labelWidth
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.team.admin_name,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.team.admin_name = $event),
          placeholder: "请输入管理员姓名",
          autocomplete: "off"
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label-width"]), _createVNode(_component_el_form_item, {
        prop: "admin_phone",
        label: "手机号：",
        "label-width": _ctx.labelWidth
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.team.admin_phone,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.team.admin_phone = $event),
          type: "number",
          placeholder: "请输入管理员手机号",
          autocomplete: "off"
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label-width"])]),
      _: 1
    }, 8, ["model", "rules"])]),
    _: 1
  }, 8, ["width", "modelValue"]);
}