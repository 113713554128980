import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.push.js";
import MarkDownPage from '@/pages/md/MarkDown.vue';
import AlertDialog from '@/components/AlertDialog.vue';
import { MESSAGE, TIPS } from './constants';
import { Select, CloseBold, PhoneFilled, CopyDocument, VideoPlay, Pointer, Tickets } from '@element-plus/icons-vue';
import { ElLoading } from 'element-plus';
import trtcClient from './trtcClient';
import handleInit from './handle-init';
import hostController from './hostController';
import memberController from './memberController';
let timer;
let timerCount;
export default {
  name: "LiveRoom",
  components: {
    // LivePlayer, 
    MarkDownPage,
    Select,
    CloseBold,
    PhoneFilled,
    CopyDocument,
    Pointer,
    VideoPlay,
    Tickets,
    AlertDialog
  },
  props: {
    aid: {
      type: Number,
      default: 0
    },
    pid: {
      type: Number,
      default: 0
    },
    hid: {
      type: Number,
      default: 0
    },
    token: {
      type: String,
      default: ''
    },
    appid: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      viewReportId: 0,
      showPerson: null,
      contactLoading: false,
      stepDes: '',
      activeName: '',
      promptName: '',
      loading: null
    };
  },
  watch: {
    viewReportId(val) {
      if (!val) {
        document.title = this.activity.name;
      }
    },
    imInfo: {
      handler(val) {
        if (val.step === 'waiting') {
          this.stepDes = '等待开始';
        } else if (val.step === 'starting') {
          this.stepDes = '开场白';
        } else if (val.step === 'introduction') {
          this.stepDes = '嘉宾自我介绍';
        } else if (val.step === 'truth') {
          this.stepDes = '真心话对对碰';
        } else {
          this.stepDes = '结束转化';
        }
        document.title = this.activity.name;
      },
      deep: true
    }
  },
  setup(props) {
    const {
      persons,
      hosts,
      user,
      imInfo,
      reportTypes,
      reports,
      activity,
      remainTime,
      confirm,
      messages,
      readed,
      ready4start,
      bgms,
      initData,
      updateStat
    } = handleInit(props);
    const {
      roomStatus,
      shareStatus,
      micStatus,
      camStatus,
      remoteUsersViews,
      remoteHostsViews,
      videoDeviceId,
      audioDeviceId,
      speakerDeviceId,
      audioMuted,
      videoMuted,
      currentBgm,
      bgmPlayed,
      videoPlayer,
      startBgm,
      pauseBgm,
      resumeBgm,
      loopBgm,
      stopBgm,
      setBgmVolume,
      initTRTC,
      enterRoom,
      handleStartShare,
      handleStopShare,
      handleStartLocalAudio,
      handleStopLocalAudio,
      handleStartLocalVideo,
      handleStopLocalVideo,
      handleExit,
      switchDevice,
      toggleAudio,
      toggleVideo,
      sendMessage,
      sendCommand,
      detect
    } = trtcClient(persons, hosts, bgms, imInfo, confirm);
    const {
      watchers,
      createRoom,
      launchLive,
      cancelManager,
      setManager,
      kickout,
      muteAll,
      mute,
      unmute,
      closeRoom,
      getWatcher,
      startIntroduction,
      setSpeaker,
      unsetSpeaker
    } = hostController(user, activity, imInfo, hosts, sendCommand, updateStat, confirm);
    const {
      ready,
      notifyReadys
    } = memberController(user, persons, ready4start, imInfo, sendCommand, updateStat);
    return {
      persons,
      hosts,
      user,
      imInfo,
      reportTypes,
      reports,
      activity,
      remainTime,
      confirm,
      messages,
      initData,
      updateStat,
      roomStatus,
      shareStatus,
      micStatus,
      camStatus,
      remoteUsersViews,
      remoteHostsViews,
      videoDeviceId,
      audioDeviceId,
      speakerDeviceId,
      audioMuted,
      videoMuted,
      currentBgm,
      bgmPlayed,
      bgms,
      videoPlayer,
      startBgm,
      pauseBgm,
      resumeBgm,
      loopBgm,
      stopBgm,
      setBgmVolume,
      initTRTC,
      enterRoom,
      handleStartShare,
      handleStopShare,
      handleStartLocalAudio,
      handleStopLocalAudio,
      handleStartLocalVideo,
      handleStopLocalVideo,
      handleExit,
      switchDevice,
      toggleAudio,
      toggleVideo,
      sendMessage,
      sendCommand,
      detect,
      watchers,
      createRoom,
      launchLive,
      cancelManager,
      setManager,
      kickout,
      muteAll,
      mute,
      unmute,
      closeRoom,
      getWatcher,
      startIntroduction,
      setSpeaker,
      unsetSpeaker,
      readed,
      ready4start,
      ready,
      notifyReadys,
      TIPS
    };
  },
  async mounted() {
    window.addEventListener('userEnter', event => {
      console.log('userEnter -> ', event.detail);
      this.handleUserEnter(event.detail);
    });
    window.addEventListener('userExit', event => {
      console.log('userExit -> ', event.detail);
      this.handleUserExit(event.detail);
    });
    window.addEventListener('newMessage', event => {
      console.log('newMessage -> ', event.detail);
      this.handleMsg(event.detail);
    });
    window.addEventListener('newCommand', event => {
      console.log('newCommand -> ', event.detail);
      const {
        command,
        data
      } = event.detail;
      this.handleCmd(command, data);
    });
    this.loading = ElLoading.service({
      lock: true,
      text: '正在准备房间',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    await this.initTRTC();
    const fail = await this.initData({
      aid: this.aid,
      hid: this.hid,
      pid: this.pid
    });
    if (fail) {
      this.loading.close();
      return;
    }
    if (this.user.isHost && this.user.id === this.activity.host.id) {
      if (this.activity.status === 'completed') {
        await this.createRoom(this.token, this.activity.id);
      }
    }
    await this.enterRoom(this.aid, this.appid, this.user.userId, this.token, true);
    if (this.imInfo.step === 'waiting') {
      if (this.user.isHost) {
        await this.handleStartLocalAudio();
      }
    } else {
      if (this.user.isHost) {
        await this.handleStartLocalAudio();
        await this.handleStartLocalVideo();
      }
      if (this.user.isMember && this.imInfo.speakers && this.imInfo.speakers.includes(this.user.id)) {
        await this.handleStartLocalAudio();
        await this.handleStartLocalVideo();
      }
    }
    timer = setInterval(() => {
      timerCount++;
      if (this.imInfo.start_at) {
        let remainTime = Math.floor(60 * 60 - (Date.now() / 1000 - this.imInfo.start_at));
        let min = Math.floor(remainTime / 60);
        let sec = remainTime % 60;
        this.remainTime = `${min} 分 ${sec} 秒`;
      }
      if (timerCount % 5 === 0) {
        timerCount = Math.floor(timerCount / 5);
      }
    }, 1000);
    window.addEventListener('beforeunload', async () => {
      console.log('退出 -> beforeunload');
      await this.handleExit();
      clearInterval(timer);
    });
    this.loading.close();
  },
  async onUnmounted() {
    console.log('退出 -> onUnmounted');
    await this.handleExit();
    clearInterval(timer);
  },
  computed: {
    menOnlineCount() {
      return this.persons.filter(p => p.online).length;
    },
    womenOnlineCount() {
      return this.persons.filter(p => p.online).length;
    },
    debug() {
      return window.location.host.startsWith('192.168') || window.location.host.startsWith('survey') || window.location.host.startsWith('localhost');
    }
  },
  methods: {
    playVideo() {
      this.$refs.videoPlayer.play().catch(err => {
        console.log('play video error -> ', err);
      });
    },
    async handleMsg(message) {
      const {
        user,
        msg
      } = message;
      this.messages.push(`${user.namePrefix} <span style="color: ${user.type === MESSAGE.USER_TYPE.HN ? 'orangered' : 'green'};font-weight: bold;">${user.name}</span>: ${msg}`);
    },
    async handleCmd(command, data) {
      switch (command) {
        case MESSAGE.CMD_TYPE.MUTE:
          if (data.id === 'all' || data.id === this.user.userId) {
            await this.updateStat();
            if (data.type === 'audio') {
              this.handleStopLocalAudio();
            } else if (data.type === 'video') {
              this.handleStopLocalVideo();
            } else if (data.type === 'all') {
              this.handleStopLocalAudio();
              this.handleStopLocalVideo();
            }
          }
          break;
        case MESSAGE.CMD_TYPE.UNMUTE:
          if (data.id === 'all' || data.id === this.user.userId) {
            await this.updateStat();
            if (data.type === 'audio') {
              this.handleStartLocalAudio();
            } else if (data.type === 'video') {
              this.handleStartLocalVideo();
            } else if (data.type === 'all') {
              this.handleStartLocalAudio();
              this.handleStartLocalVideo();
            }
          }
          break;
        case MESSAGE.CMD_TYPE.SET_MANAGER:
          {
            const host = await this.getWatcher(data.id, MESSAGE.USER_TYPE.HN);
            if (this.user.type === 'h' && data.id === this.user.id) {
              this.user.isHost = host.me = true;
              this.confirm = {
                title: '提示',
                content: '你被房主设为主持人，是否需要打开摄像头和麦克风？',
                buttonConfirm: '确认',
                showCancel: true,
                show: true,
                callback: async () => {
                  this.handleStartLocalAudio();
                  if (this.activityStep !== 'waiting') {
                    this.handleStartLocalVideo();
                  }
                }
              };
              await this.updateStat();
            }
            this.hosts.push(host);
            this.watchers = this.watchers.filter(watcher => watcher.id !== data.id);
          }
          break;
        case MESSAGE.CMD_TYPE.DEL_MANAGER:
          {
            const host = this.hosts.find(h => h.id === data.id);
            if (this.user.type === 'h' && data.id === this.user.id) {
              this.handleStopLocalAudio();
              this.handleStopLocalVideo();
              this.confirm = {
                title: '提示',
                content: '你被房主撤销了主持人，已转为观众',
                buttonConfirm: '确认',
                show: true,
                showCancel: false
              };
              this.user.isHost = false;
            }
            this.hosts = this.hosts.filter(host => host.id !== data.id);
            this.watchers.push(host);
          }
          break;
        case MESSAGE.CMD_TYPE.READY_PIDS:
          console.log('ready pids -> ', data.pids);
          this.persons.map(p => p.ready = data.pids.includes(p.id));
          this.ready4start = data.pids.includes(this.user.id);
          break;
        case MESSAGE.CMD_TYPE.STATUS:
          //非命令发起者
          await this.updateStat();
          console.log('强制刷新 -> ', this.imInfo);
          if (this.user.isHost) {
            // 强制刷新主持人的设备开关状态
            if (this.imInfo.step === 'waiting') {
              this.handleStartLocalAudio();
            } else {
              this.handleStartLocalAudio();
              this.handleStartLocalVideo();
            }
          }
      }
    },
    async handleUserEnter(event) {
      const {
        id,
        type
      } = event;
      if (type === MESSAGE.USER_TYPE.PERSON) {
        console.log('用户进入 -> ', id, type);
        const person = this.persons.find(p => p.id === id);
        if (person) {
          // 嘉宾进入
          person.online = true;
          this.messages.push(`${person.namePrefix} <span style="color: green;font-weight: bold;">${person.displayName}</span> 进入房间`);
        } else {
          // 游客进入
          if (this.user.isHost) {
            // 仅主持人可见
            const watcher = await this.getWatcher(id, type);
            this.messages.push(`游客 <span style="color: green;font-weight: bold;">${watcher.name || watcher.extra_string_1}</span> 进入房间`);
            this.notifyReadys();
          }
        }
      } else {
        let hn = this.hosts.find(h => h.id === id);
        console.log('handleUserEnter -> ', hn);
        // 红娘进入   仅主持人可见
        if (this.user.isHost) {
          hn = hn ? hn : await this.getWatcher(id, type);
          this.messages.push(`红娘 <span style="color: green;font-weight: bold;">
                        ${hn.name}</span> 进入房间`);
          this.notifyReadys();
        }
      }
      if (!this.isUserScrolling) {
        this.scrollToBottom();
      }
    },
    async handleUserExit(event) {
      const {
        id,
        type
      } = event;
      if (type === MESSAGE.USER_TYPE.PERSON) {
        const person = this.persons.find(p => p.id === id);
        if (person) {
          // 嘉宾退出
          person.online = false;
          this.messages.push(`${person.namePrefix} <span style="color: orangered;font-weight: bold;">${person.displayName}</span> 离开房间`);
        } else {
          // 游客退出
          const watcher = await this.getWatcher(id, type);
          if (this.user.isHost) {
            // 仅主持人可见
            this.messages.push(`游客 <span style="color: orangered;font-weight: bold;">${watcher.name || watcher.extra_string_1}</span> 离开房间`);
          }
        }
      } else {
        // 红娘
        let hn = this.hosts.find(h => h.id === id);
        if (this.user.isHost) {
          // 仅主持人可见
          hn = hn ? hn : await this.getWatcher(id, type);
          this.messages.push(`红娘 <span style="color: orangered;font-weight: bold;">${hn.name}</span> 离开房间`);
        }
      }
      if (!this.isUserScrolling) {
        this.scrollToBottom();
      }
    },
    // sofaroom
    openPerson(id) {
      console.log('查看嘉宾信息 -> ', id);
      if (this.user.isMember) {
        if (!this.ready4start && this.imInfo.step === 'waiting') {
          this.confirm = {
            title: '提示',
            content: '请先阅读《活动声明》及《注意事项》并准备',
            buttonConfirm: '确认',
            show: true,
            showCancel: false
          };
          return;
        }
      } else if (!this.user.isHost) {
        if (this.activity.status !== 'living') {
          this.confirm = {
            title: '提示',
            content: '观众无法查看',
            buttonConfirm: '确认',
            show: true,
            showCancel: false
          };
          return;
        }
      }
      this.showPerson = this.persons.find(p => p.id === id);
      console.log('show person -> ', this.reportTypes);
    },
    viewReport(report) {
      let tid = report.id;
      if (!tid) {
        const s_pid = Math.min(report.s_pid, report.t_pid);
        const t_pid = Math.max(report.s_pid, report.t_pid);
        this.reports.map(t => {
          if (t.type === report.type && s_pid === t.s_pid && t_pid === t.t_pid) {
            tid = t.id;
          }
        });
      }
      this.viewReportId = String(tid);
    },
    // 滚动到底部
    scrollToBottom() {
      const messageContainer = this.$refs.messageContainer;
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }
    },
    // 处理用户滚动事件
    handleScroll() {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout); // 清除上一个定时器
      }
      this.isUserScrolling = true; // 用户正在手动滚动
      // 5秒后恢复自动滚动
      this.scrollTimeout = setTimeout(() => {
        this.isUserScrolling = false;
        this.scrollToBottom(); // 恢复自动滚动
      }, 5000);
    }
  }
};