import { ref } from 'vue';
import wx from 'weixin-js-sdk';
import liveService from '@/module/liveroom';

/**
 * 初始化房间数据
 * @returns 
 */
export default function () {
    // 活动嘉宾列表
    const persons = ref([])
    // 活动主持人列表
    const hosts = ref([])
    // 活动信息
    const activity = ref({})
    // 活动即时信息
    const imInfo = ref({})
    // 当前用户
    const user = ref({})
    // 嘉宾报告列表
    const reports = ref([])
    // 嘉宾报告类型
    const reportTypes = ref([true, true])
    // 房间剩余时间
    const remainTime = ref('')
    // 弹窗对话框
    const confirm = ref({
        show: false,
        title: '提示'
    })
    // 消息列表
    const messages = ref([])
    // 是否已读声明
    const readed = ref('no')
    // 是否准备好开始
    const ready4start = ref(false)
    // 背景音乐列表
    const bgms = ref([])

    const initData = async ({ aid, pid, hid }) => {
        if (checkBefore({ aid, pid, hid })) {
            return true
        }
        const details = await liveService.detail({ aid, pid, hid })
        console.log('活动详情 -> ', details)
        activity.value = details.activity
        user.value = details.user
        reportTypes.value = details.activity.report_types
        reports.value = details.tasks
        persons.value = details.persons.map(p => {
            p.namePrefix = `${p.index + 1}号${p.gender === 1 ? '男' : '女'}嘉宾`
            p.online = p.me = user.value.type === 'p' && user.value.id === p.id
            p.displayName = p.me ? '我' : (p.name || p.extra_string_1)
            return p
        })
        hosts.value = details.hosts.map(h => {
            h.online = h.me = user.value.type === 'h' && user.value.id === h.id
            return h
        })
        if (checkAfter()) {
            return true
        }

        await updateStat()

        if (user.value.isHost) {
            bgms.value = [
                { id: 1, url: require('@/assets/bgms/1.mp3') },
                { id: 2, url: require('@/assets/bgms/2.mp3') },
                { id: 3, url: require('@/assets/bgms/3.mp3') },
                { id: 4, url: require('@/assets/bgms/4.mp3') },
                { id: 5, url: require('@/assets/bgms/5.mp3') },
                { id: 6, url: require('@/assets/bgms/6.mp3') },
                { id: 7, url: require('@/assets/bgms/7.mp3') },
                { id: 8, url: require('@/assets/bgms/8.mp3') },
                { id: 9, url: require('@/assets/bgms/9.mp3') },
                { id: 10, url: require('@/assets/bgms/10.mp3') },
                { id: 11, url: require('@/assets/bgms/11.mp3') },
                { id: 12, url: require('@/assets/bgms/12.mp3') },
                { id: 13, url: require('@/assets/bgms/13.mp3') },
                { id: 14, url: require('@/assets/bgms/14.mp3') },
                { id: 15, url: require('@/assets/bgms/15.mp3') },
                { id: 16, url: require('@/assets/bgms/16.mp3') },
                { id: 17, url: require('@/assets/bgms/17.mp3') },
                { id: 18, url: require('@/assets/bgms/18.mp3') },
                { id: 19, url: require('@/assets/bgms/19.mp3') },
                { id: 20, url: require('@/assets/bgms/20.mp3') },
                { id: 21, url: require('@/assets/bgms/21.mp3') },
                { id: 22, url: require('@/assets/bgms/22.mp3') },
                { id: 23, url: require('@/assets/bgms/23.mp3') },
                { id: 24, url: require('@/assets/bgms/24.mp3') },
                { id: 25, url: require('@/assets/bgms/25.mp3') },
                { id: 26, url: require('@/assets/bgms/26.mp3') },
                { id: 27, url: require('@/assets/bgms/27.mp3') },
                { id: 28, url: require('@/assets/bgms/28.mp3') },
                { id: 29, url: require('@/assets/bgms/29.mp3') },
                { id: 30, url: require('@/assets/bgms/30.mp3') },
                { id: 31, url: require('@/assets/bgms/31.mp3') },
                { id: 32, url: require('@/assets/bgms/32.mp3') }
            ]
        }
        return false
    }

    const checkAfter = () => {
        if (activity.value.status === 'end') {
            confirm.value = {
                title: '提示',
                content: '活动已结束',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            console.log('活动已结束')
            return true
        }
        if (user.value.id !== activity.value.host.id && activity.value.status !== 'living') {
            confirm.value = {
                title: '提示',
                content: '活动房间还未开放，请联系活动主理人确认',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            console.log('活动房间还未开放，请联系活动主理人确认')
            return true
        }
        return false
    }

    const checkBefore = (data) => {
        if (!data.hid && !data.pid) {
            confirm.value = {
                title: '提示',
                content: '没有权限',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
            return true
        }
        return false
    }

    const updateStat = async (stat) => {
        const extras = await liveService.stat({ aid: activity.value.id, extras: stat })
        imInfo.value.step = extras ? extras.step : 'waiting'
        if (imInfo.value.step === 'waiting') {
            imInfo.value.readyPids = extras?.readyPids ? extras.readyPids.map(p => parseInt(p)) : []
            persons.value.map(p => {
                p.ready = imInfo.value.readyPids.includes(p.id)
                if (p.me) {
                    ready4start.value = p.ready
                }
            })
        } else if (imInfo.value.step === 'introduction') {
            imInfo.value.speakers = extras?.speakers ? extras.speakers : []
            user.value.isSpeaker = imInfo.value.speakers.includes(user.value.id)
        }
        console.log('获取房间即时信息 -> ', imInfo.value, ready4start.value)
        try {
            imInfo.value.start_at = extras?.start_at ? parseInt(extras.start_at) : 0
        } catch (e) {
            imInfo.value.start_at = 0
        }
        return
    }

    return {
        persons,
        hosts,
        user,
        imInfo,
        reportTypes,
        reports,
        activity,
        remainTime,
        messages,
        confirm,
        readed,
        ready4start,
        bgms,
        initData,
        updateStat
    }
}