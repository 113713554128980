import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router'; // 导入路由
import store from '@/store'; // 导入状态管理
import VueLazyload from 'vue-lazyload-next';
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

createApp(App)
  .use(router) // 使用路由
  .use(store) // 使用状态管理
  .use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
  })
  .mount('#app');

