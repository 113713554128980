import TRTC from 'trtc-sdk-v5';
import { DeviceDetector } from 'trtc-sdk-v5/plugins/device-detector';
import { ref, nextTick } from 'vue';
import { BasicBeauty } from 'trtc-sdk-v5/plugins/video-effect/basic-beauty';
import { MESSAGE } from './constants'
import wx from 'weixin-js-sdk';

const trtc = TRTC.create({ plugins: [DeviceDetector, BasicBeauty] })
TRTC.setLogLevel(5);

export default function (persons, hosts, bgms, imInfo, confirm) {
    // 视频播放标签
    const videoPlayer = ref(null)
    // 房间状态
    const roomStatus = ref('')
    // 分享屏幕状态
    const shareStatus = ref('')
    // 麦克风状态
    const micStatus = ref('')
    // 摄像头状态
    const camStatus = ref('')
    // 当前远端用户信息
    const remoteUsersViews = ref([])
    // 当前远端主持人信息
    const remoteHostsViews = ref([])
    // 当前选择的摄像头id
    const videoDeviceId = ref('')
    // 当前选择的麦克风id
    const audioDeviceId = ref('')
    // 当前选择的扬声器id
    const speakerDeviceId = ref('')
    // 音频是否静音
    const audioMuted = ref(false)
    // 视频是否静音
    const videoMuted = ref(false)
    // 当前背景音乐
    const currentBgm = ref({
        volume: 30,
        loop: true,
        onEnded: () => {
            const index = (currentBgm.value.id + 1) % 32
            currentBgm.value.id = bgms.value.bgms[index].id
            currentBgm.value.url = bgms.value.bgms[index].url
        }
    })
    // 背景音乐是否已播放
    const bgmPlayed = ref(false)

    const initTRTC = async () => {
        await updateDevice()
        // await trtc.startPlugin('BasicBeauty', {
        //     beauty: 0.5, // 美颜
        //     brightness: 0.5, // 明亮
        //     ruddy: 0.5, // 红润
        // });
    }

    // 进入房间
    const enterRoom = async (roomId, sdkAppId, userId, userSig, isAnchor) => {
        roomStatus.value = 'entering'
        try {
            await trtc.enterRoom({
                roomId,
                sdkAppId: parseInt(sdkAppId),
                userId,
                userSig,
                scene: TRTC.TYPE.SCENE_LIVE,
                enableAutoPlayDialog: false,
                role: isAnchor ? TRTC.TYPE.ROLE_ANCHOR : TRTC.TYPE.ROLE_AUDIENCE
            });
            roomStatus.value = 'entered';

            installEventHandlers();

            console.log(`用户[${userId}] 进入房间 [${roomId}] 成功`);
        } catch (error) {
            roomStatus.value = 'stopped';
            console.error(`进入房间 ${roomId} 失败，错误: ${error.message}`);
        }
    }

    const startBgm = ({ id, url }) => {
        console.log('startBgm -> ', id, url)
        currentBgm.value.id = id
        currentBgm.value.url = url
        trtc.startPlugin('AudioMixer', {
            id: String(id), url
        })
        bgmPlayed.value = true
    }

    const pauseBgm = () => {
        trtc.updatePlugin('AudioMixer', {
            id: String(currentBgm.value.id),
            operation: 'pause'
        })
        currentBgm.value.paused = true
    }

    const resumeBgm = () => {
        trtc.updatePlugin('AudioMixer', {
            id: String(currentBgm.value.id),
            operation: 'resume'
        })
        currentBgm.value.paused = false
    }

    const loopBgm = () => {
        trtc.updatePlugin('AudioMixer', {
            id: String(currentBgm.value.id),
            loop: !currentBgm.value.loop
        })
        currentBgm.value.loop = !currentBgm.value.loop
    }

    const stopBgm = () => {
        trtc.stopPlugin('AudioMixer', { id: String(currentBgm.value.id) })
        bgmPlayed.value = false
    }

    const setBgmVolume = (volume) => {
        if (bgmPlayed.value) {
            trtc.updatePlugin('AudioMixer', {
                id: String(currentBgm.value.id),
                volume: volume / 100
            })
        }
        currentBgm.value.volume = volume
    }

    /** 本地屏幕已共享 */
    const handleStartShare = async () => {
        shareStatus.value = 'sharing';
        try {
            await trtc.startScreenShare();
            shareStatus.value = 'shared';
            console.log('本地屏幕已共享');
        } catch (error) {
            shareStatus.value = 'stopped';
            console.error(`本地屏幕共享失败: ${error.message}`);
        }
    }

    /** 更新设备并默认选择第一个设备 */
    const updateDevice = async () => {
        const cameraItems = await TRTC.getCameraList();
        cameraItems.forEach((item) => { item.value = item.deviceId; });
        const microphoneItems = await TRTC.getMicrophoneList();
        microphoneItems.forEach((item) => { item.value = item.deviceId; });
        const speakerItems = await TRTC.getSpeakerList();
        if (!videoDeviceId.value && cameraItems[0]) {
            videoDeviceId.value = cameraItems[0].deviceId;
        }

        if (!audioDeviceId.value && microphoneItems[0]) {
            audioDeviceId.value = microphoneItems[0].deviceId;
        }

        if (!speakerDeviceId.value && speakerItems[0]) {
            speakerDeviceId.value = speakerItems[0].deviceId;
        }
    }
    /** 本地屏幕已共享 */
    const handleStopShare = async () => {
        if (shareStatus.value !== 'shared') {
            console.error('The Share is not started');
            return;
        }
        shareStatus.value = 'stopping';
        try {
            await trtc.stopScreenShare();
            shareStatus.value = 'stopped';
            console.log('Stop share screen success');
        } catch (error) {
            shareStatus.value = 'shared';
            console.error(`Stop share error: ${error.message}`);
        }
    }
    /** 开启本地音频上传 */
    const handleStartLocalAudio = async () => {
        if (micStatus.value === 'started') {
            console.log('The audio has been started');
            return;
        }
        micStatus.value = 'starting';
        try {
            await trtc.startLocalAudio({
                option: {
                    microphoneId: audioDeviceId.value,
                }
            });
            micStatus.value = 'started';
            console.log('本地音频已共享');
        } catch (error) {
            micStatus.value = 'stopped';
            console.error(`本地音频共享错误: ${error.message}`);
        }
    }

    /** 关闭本地音频上传 */
    const handleStopLocalAudio = async () => {
        if (micStatus.value !== 'started') {
            console.error('The audio has not been started');
            return;
        }
        micStatus.value = 'stopping';
        try {
            await trtc.stopLocalAudio();
            micStatus.value = 'stopped';
            console.log('本地音频已停止');
        } catch (error) {
            micStatus.value = 'started';
            console.error(`本地音频停止错误: ${error.message}`);
        }
    }

    /** 开启本地视频上传 */
    const handleStartLocalVideo = async () => {
        if (camStatus.value === 'started') {
            console.log('The video has been started');
            return;
        }
        camStatus.value = 'starting';
        try {
            await trtc.startLocalVideo({
                view: 'local',
                option: {
                    cameraId: videoDeviceId.value,
                    // profile: { width: 720, height: 1280, frameRate: 15, bitrate: 1500 /* kpbs */ },
                    profile: '120p'
                }
            });
            camStatus.value = 'started';
            console.log('本地视频已共享');
        } catch (error) {
            camStatus.value = 'stopped';
            console.error(`本地视频共享错误: ${error.message}`);
        }
    }

    /** 关闭本地视频上传 */
    const handleStopLocalVideo = async () => {
        if (camStatus.value !== 'started') {
            console.error('The audio has not been started');
            return;
        }
        camStatus.value = 'stopping';
        try {
            await trtc.stopLocalVideo();
            camStatus.value = 'stopped';
            console.log('本地视频已停止');
        } catch (error) {
            camStatus.value = 'started';
            console.error(`本地视频停止错误: ${error.message}`);
        }
    }
    /** 退出房间 */
    const handleExit = async () => {
        if (roomStatus.value !== 'entered') {
            console.error('The room has not been entered');
            return;
        }
        roomStatus.value = 'exiting';
        try {
            uninstallEventHandlers();
            await trtc.exitRoom();
            await trtc.stopLocalVideo();
            await trtc.stopLocalAudio();
            roomStatus.value = 'exited';
            remoteUsersViews.value = [];
            remoteHostsViews.value = []
            console.log('Exit room success');
        } catch (error) {
            roomStatus.value = 'entered';
            console.error(`Exit room failed. Error: ${error.message}`);
        }

        if (micStatus.value === 'started') handleStopLocalAudio();
        if (camStatus.value === 'started') handleStopLocalVideo();
        if (shareStatus.value === 'shared') handleStopShare();
    }

    /** 切换音视频设备 */
    const switchDevice = async ({ videoId, audioId }) => {
        if (videoId) {
            try {
                await trtc.updateLocalVideo({
                    option: { cameraId: videoId }
                });
                console.log('Switch video device success');
            } catch (error) {
                console.error('Switch video device failed');
            }
        }
        if (audioId) {
            try {
                await trtc.updateLocalAudio({
                    option: { microphoneId: audioId }
                });
                console.log('Switch audio device success');
            } catch (error) {
                console.error('Switch audio device failed');
            }
        }
    }

    /** 关闭或打开麦克风 */
    const toggleAudio = async () => {
        try {
            if (!audioMuted.value) {
                await trtc.updateLocalAudio({ mute: true });
                audioMuted.value = true;
            } else {
                await trtc.updateLocalAudio({ mute: false });
                audioMuted.value = false;
            }
            console.log(`麦克风: ${audioMuted.value}`);
        } catch (error) {
            console.error(`麦克风 error: ${error.message}`);
        }
    }

    /** 关闭或打开摄像头 */
    const toggleVideo = async () => {
        try {
            if (!videoMuted.value) {
                await trtc.updateLocalVideo({ mute: true });
                videoMuted.value = true;
            } else {
                await trtc.updateLocalVideo({ mute: false });
                videoMuted.value = false;
            }
            console.log(`摄像头: ${videoMuted.value ? '已关闭' : '已打开'}`);
        } catch (error) {
            console.error(`摄像头 error: ${error.message}`);
        }
    }

    const handleError = (error) => {
        console.error(`Local error: ${error.message}`);
    }

    const installEventHandlers = () => {
        trtc.on(TRTC.EVENT.ERROR, handleError);
        trtc.on(TRTC.EVENT.KICKED_OUT, handleKickedOut);
        trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, handleRemoteUserEnter);
        trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, handleRemoteUserExit);
        trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, handleRemoteVideoAvailable);
        trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, handleRemoteVideoUnavailable);
        trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, handleRemoteAudioUnavailable);
        trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, handleRemoteAudioAvailable);
        trtc.on(TRTC.EVENT.SCREEN_SHARE_STOPPED, handleScreenShareStopped);
        trtc.on(TRTC.EVENT.CUSTOM_MESSAGE, handleMessage)
        trtc.on(TRTC.EVENT.AUTOPLAY_FAILED, showResumePlay)
    }

    const uninstallEventHandlers = () => {
        trtc.off(TRTC.EVENT.ERROR, handleError);
        trtc.off(TRTC.EVENT.KICKED_OUT, handleKickedOut);
        trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, handleRemoteUserEnter);
        trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, handleRemoteUserExit);
        trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, handleRemoteVideoAvailable);
        trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, handleRemoteVideoUnavailable);
        trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, handleRemoteAudioUnavailable);
        trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, handleRemoteAudioAvailable);
        trtc.off(TRTC.EVENT.SCREEN_SHARE_STOPPED, handleScreenShareStopped);
        trtc.off(TRTC.EVENT.CUSTOM_MESSAGE, handleMessage)
        trtc.off(TRTC.EVENT.AUTOPLAY_FAILED, showResumePlay)
    }

    /** 处理被踢出房间 */
    const handleKickedOut = (event) => {
        console.log('Kicked out -> ', event);
        if (event.reason === 'room_disband') {
            confirm.value = {
                title: '提示',
                content: '活动已结束',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
        } else if (event.reason === 'banned') {
            confirm.value = {
                title: '提示',
                content: '您被请出了房间',
                buttonConfirm: '确认',
                showCancel: false,
                show: true,
                callback: () => wx.miniProgram && wx.miniProgram.navigateBack()
            }
        }
    }

    /** 发送即时文本消息 */
    const sendMessage = (text) => {
        console.log('sendCommand -> ', text)
        return trtc.sendCustomMessage({
            cmdId: MESSAGE.TYPE.TEXT,
            data: new TextEncoder().encode(text).buffer
        });
    }

    /** 发送即时命令消息 */
    const sendCommand = (cmdType, content) => {
        console.log('sendCommand -> ', cmdType, content)
        // 命令发起者自处理
        if (cmdType === MESSAGE.CMD_TYPE.STATUS && content === 'starting') {
            handleStartLocalAudio()
            handleStartLocalVideo()
        }
        return trtc.sendCustomMessage({
            cmdId: MESSAGE.TYPE.COMMAND,
            data: new TextEncoder().encode(`${cmdType}:${content}`).buffer
        });
    }

    /** 处理新用户进入房间 */
    const handleRemoteUserEnter = (event) => {
        const { userId } = event;
        const elems = userId.split('-')
        if (elems[0] === 'p') {
            window.dispatchEvent(new CustomEvent('userEnter', {
                detail: { type: MESSAGE.USER_TYPE.PERSON, id: parseInt(elems[1]) }
            }));
        } else {
            window.dispatchEvent(new CustomEvent('userEnter', {
                detail: { type: MESSAGE.USER_TYPE.HN, id: parseInt(elems[1]) }
            }));
        }
    }

    /** 处理用户退出房间 */
    const handleRemoteUserExit = (event) => {
        const { userId } = event;
        const elems = userId.split('-')
        if (elems[0] === 'p') {
            window.dispatchEvent(new CustomEvent('userExit', {
                detail: { type: MESSAGE.USER_TYPE.PERSON, id: parseInt(elems[1]) }
            }));
        } else { // 红娘
            window.dispatchEvent(new CustomEvent('userExit', {
                detail: { type: MESSAGE.USER_TYPE.HN, id: parseInt(elems[1]) }
            }));
        }
    }

    /** 处理远程视频可用 */
    const handleRemoteVideoAvailable = async (event) => {
        const { userId, streamType } = event;
        try {
            console.log(`用户[${userId}] [${streamType}] 远程视频可用`);
            const elems = userId.split('-')
            if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
                if (elems[0] === 'p') {
                    const person = persons.value.find(p => p.id === parseInt(elems[1]))
                    remoteUsersViews.value.push({
                        tag: `${userId}_main`,
                        namePrefix: person.namePrefix,
                        displayName: person.displayName,
                        header: person.photos[0]
                    });
                    console.log('remoteUsersViews -> ', remoteHostsViews.value)
                } else {
                    const host = hosts.value.find(h => h.id === parseInt(elems[1]))
                    remoteHostsViews.value.push({
                        tag: `${userId}_main`,
                        namePrefix: '主持人',
                        displayName: host.name,
                        header: host.header
                    })
                    console.log('remoteHostsViews -> ', remoteHostsViews.value)
                }
                await nextTick();
                await trtc.startRemoteVideo({ userId, streamType, view: `${userId}_main` });
            } else { // 暂时不处理屏幕分享
                if (elems[0] === 'p') {
                    remoteUsersViews.value.push(`${userId}_screen`);
                } else {
                    remoteHostsViews.value.push(`${userId}_screen`);
                }
                await nextTick();
                trtc.startRemoteVideo({ userId, streamType, view: `${userId}_screen` });
            }
            console.log(`播放远程视频成功: [${userId}]`);
        } catch (error) {
            console.error(`播放远程视频失败: [${userId}], error: ${error.message}`);
        }
    }

    /** 处理远程视频不可用 */
    const handleRemoteVideoUnavailable = (event) => {
        console.log(`用户[${event.userId}] [${event.streamType}] 远程视频不可用`);
        const { streamType } = event;
        const type = event.userId.split('-')[0]
        trtc.stopRemoteVideo({ userId: event.userId, streamType });
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
            if (type === 'p') {
                remoteUsersViews.value = remoteUsersViews.value.filter((p) => p.tag !== `${event.userId}_main`);
            } else {
                remoteHostsViews.value = remoteHostsViews.value.filter((h) => h.tag !== `${event.userId}_main`);
            }
        } else {
            if (type === 'p') {
                remoteUsersViews.value = remoteUsersViews.value.filter((p) => p.tag !== `${event.userId}_screen`);
            } else {
                remoteHostsViews.value = remoteHostsViews.value.filter((h) => h.tag !== `${event.userId}_screen`);
            }
        }
    }

    /** 处理远程音频不可用 */
    const handleRemoteAudioUnavailable = (event) => {
        const elems = event.userId.split('-')
        if (elems[0] === 'h') {
            const host = hosts.value.find(h => h.id === parseInt(elems[1]))
            host.online = false
        }
    }

    /** 处理远程音频可用 */
    const handleRemoteAudioAvailable = (event) => {
        console.log(`用户[${event.userId}] 远程音频可用`);
        const elems = event.userId.split('-')
        if (elems[0] === 'h') {
            const host = hosts.value.find(h => h.id === parseInt(elems[1]))
            host.online = true
        } else {
            const person = persons.value.find(p => p.id === parseInt(elems[1]))
            person.online = true
        }
    }

    /** 处理屏幕分享停止 */
    const handleScreenShareStopped = () => {
        shareStatus.value = 'stopped';
        console.log('Stop share screen success');
    }

    /** 处理自定义消息 */
    const handleMessage = async (event) => {
        // event.userId: 远端发消息的 userId
        // event.cmdId: 您自定义的消息 Id
        // event.seq: 消息的序号
        // event.data: 消息内容，ArrayBuffer 类型
        const msg = new TextDecoder().decode(event.data)
        console.log('收到消息:', msg)
        const elems = msg.split(':')
        switch (event.cmdId) {
            case MESSAGE.TYPE.TEXT: {
                if (elems[0] === 'p') {
                    const person = persons.value.find(p => p.id === parseInt(elems[1]))
                    window.dispatchEvent(new CustomEvent('newMessage', {
                        detail: {
                            user: {
                                id: person.id,
                                type: MESSAGE.USER_TYPE.PERSON,
                                namePrefix: person.namePrefix,
                                name: person.displayName
                            }, msg
                        }
                    }))
                } else {
                    const host = hosts.value.find(h => h.id === parseInt(elems[1]))
                    window.dispatchEvent(new CustomEvent('newMessage', {
                        detail: {
                            user: {
                                id: host.id,
                                type: MESSAGE.USER_TYPE.HN,
                                namePrefix: '主持人',
                                name: host.name
                            }, msg
                        }
                    }))
                }
            }
                break;
            case MESSAGE.TYPE.COMMAND: {
                const cmdType = parseInt(elems[0])
                if (cmdType === MESSAGE.CMD_TYPE.MUTE) { // mute:audio:p-173
                    // cmdid:type:userId id=all 代表所有人 demo 1:audio:0
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.MUTE, data: { id: elems[2], type: elems[1] } }
                    }))
                } else if (cmdType === MESSAGE.CMD_TYPE.UNMUTE) { // open:audio:p-173
                    // cmdid:type:userId id=all 代表所有人 demo 1:audio:0
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.UNMUTE, data: { id: elems[2], type: elems[1] } }
                    }))
                } else if (cmdType === MESSAGE.CMD_TYPE.SET_MANAGER) {
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.SET_MANAGER, data: { id: parseInt(elems[1]) } }
                    }))
                } else if (cmdType === MESSAGE.CMD_TYPE.DEL_MANAGER) {
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.DEL_MANAGER, data: { id: parseInt(elems[1]) } }
                    }))
                } else if (cmdType === MESSAGE.CMD_TYPE.READY_PIDS) {
                    console.log('准备嘉宾:', msg)
                    const pids = elems[1].split(',').filter(id => !!id).map(id => parseInt(id))
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.READY_PIDS, data: { pids } }
                    }))
                } else if (cmdType === MESSAGE.CMD_TYPE.STATUS) {
                    window.dispatchEvent(new CustomEvent('newCommand', {
                        detail: { command: MESSAGE.CMD_TYPE.STATUS }
                    }))
                }
            }
        }
    }

    const showResumePlay = () => {
        confirm.value = {
            title: '认识新朋友，今天就开始！',
            content: '欢迎你的到来',
            buttonConfirm: '即刻开始',
            showCancel: false,
            show: true,
            callback: () => {
                if (imInfo.step === 'starting') {
                    videoPlayer.value.play().catch(err => console.error('播放错误 -> ', err))
                }
            }
        }
    }


    const detect = async () => {
        try {
            const result = await trtc.startPlugin('DeviceDetector');
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    return {
        roomStatus,
        shareStatus,
        micStatus,
        camStatus,
        remoteUsersViews,
        remoteHostsViews,
        videoDeviceId,
        audioDeviceId,
        speakerDeviceId,
        audioMuted,
        videoMuted,
        videoPlayer,
        initTRTC,
        enterRoom,
        handleStartShare,
        handleStopShare,
        handleStartLocalAudio,
        handleStopLocalAudio,
        handleStartLocalVideo,
        handleStopLocalVideo,
        handleExit,
        switchDevice,
        toggleAudio,
        toggleVideo,
        sendMessage,
        sendCommand,
        detect,
        bgms,
        currentBgm,
        bgmPlayed,
        startBgm,
        pauseBgm,
        resumeBgm,
        loopBgm,
        stopBgm,
        setBgmVolume
    }
}