import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import cytoscape from 'cytoscape';
import { mapActions, mapGetters } from 'vuex';
import message from '@/assets/js/message';
import AlertDialog from '@/components/AlertDialog.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import MarkDownPage from '@/pages/md/MarkDown.vue';
import relationService from '@/module/relation';
import ClipboardJS from 'clipboard';
import wx from 'weixin-js-sdk';
import { ElLoading } from 'element-plus';
import { Select, CloseBold, PhoneFilled, Promotion, CopyDocument } from '@element-plus/icons-vue';
const delayPromise = duration => new Promise(resolve => setTimeout(resolve, duration));
const scale = window.innerWidth / 960;
const layoutPadding = 10 * scale;
const animationDuration = 500;
const easing = 'ease';
export default {
  name: 'ActivityRoom',
  components: {
    MarkDownPage,
    Select,
    CloseBold,
    PhoneFilled,
    Promotion,
    CopyDocument,
    SwiperSlide,
    Swiper,
    AlertDialog
  },
  props: {
    aid: {
      type: Number,
      default: 0
    },
    pid: {
      type: Number,
      default: 0
    },
    hid: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      cy: null,
      fontSize: 16,
      lastHighlighted: null,
      hn: null,
      infoNode: null,
      showPerson: null,
      matchPersons: [],
      relations: [],
      reportTypes: [true, true],
      hasHighlight: false,
      highlightInProgress: false,
      originalPoisitons: {},
      viewReportId: 0,
      lastSubHighligth: null,
      confirmShow: false,
      confirm: {},
      loading: null,
      person: null,
      contactLoading: false
    };
  },
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    debug() {
      return window.location.host.startsWith('192.168') || window.location.host.startsWith('survey');
    }
  },
  async mounted() {
    function checkEnv() {
      return new Promise((resolve, reject) => {
        let needLogin = true;
        if (typeof wx !== 'undefined' && wx.miniProgram) {
          wx.miniProgram.getEnv(res => {
            if (res.miniprogram) {
              needLogin = false;
            }
          });
        }
        const host = window.location.host;
        // 本地测试以及开发环境不做限制
        if (host.startsWith('192.168') || host.startsWith('survey')) {
          needLogin = false;
        }
        needLogin = false;
        setTimeout(() => {
          if (needLogin) {
            reject({
              needLogin
            });
          } else {
            resolve(true);
          }
        }, 100);
      });
    }
    document.title = '活动房间配对详情';
    try {
      await checkEnv();
      await this.init();
      window.addEventListener('resize', async () => {
        await this.init();
      });
    } catch (err) {
      if (err.needLogin) {
        this.confirm = {
          title: '提示',
          content: '请在AI牵红绳小程序中打开',
          buttonConfirm: '确认',
          showCancel: false,
          callback: () => window.location.replace('https://www.ylaiai.com')
        };
        this.confirmShow = true;
      } else {
        this.confirm = {
          title: '提示',
          content: err,
          buttonConfirm: '确认',
          showCancel: false,
          callback: () => {
            if (wx?.miniProgram) {
              wx.miniProgram.navigateBack();
            } else {
              window.location.replace('https://www.ylaiai.com');
            }
          }
        };
        this.confirmShow = true;
      }
    } finally {
      this.loading.close();
    }
    new ClipboardJS('.copyBtn', {
      target: function (trigger) {
        var tempDiv = document.createElement('div');
        tempDiv.textContent = trigger.getAttribute('phone');
        document.body.appendChild(tempDiv);
        return tempDiv;
      }
    });
  },
  methods: {
    ...mapActions('activity', ['initActivity']),
    ...mapGetters('activity', ['getHn', 'getWomen', 'getTasks', 'getActivity', 'getPerson', 'getMen']),
    makePhoneCall(phone) {
      window.location.href = `tel:${phone}`;
    },
    copied() {
      message.success('已复制');
    },
    async downloadImage() {
      if (typeof wx !== 'undefined' && wx.miniProgram) {
        const base64 = await this.$refs['mdViewer'].downloadImage();
        var arr = base64.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr], {
          type: mime
        });

        // 创建一个指向Blob对象的URL
        var url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = document.title + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // wx.miniProgram.postMessage({ data: { type: 'screenshot', screenshot: url } });
        // wx.previewImage({
        //     current: '报告截图', // 当前显示图片的http链接
        //     urls: [url] // 需要预览的图片http链接列表
        // });
      }
    },
    loadRelation() {
      const pids = this.person.gender === 1 ? this.activity.women_ids : this.activity.man_ids;
      const persons = this.person.gender === 1 ? this.women : this.men;
      relationService.findRelations({
        pid: this.person.id,
        pids
      }).then(res => {
        if (res) {
          this.relations = [];
          pids.map((pid, i) => {
            this.relations.push({
              target: persons.find(p => p.id === pid),
              relation: res[i]
            });
          });
        }
      }).catch(console.warn);
    },
    loadAllRelation() {
      const persons = this.women.concat(this.men);
      relationService.findRelationsByActivity({
        aid: this.activity.id
      }).then(res => {
        if (res) {
          this.relations = [];
          res.map(relation => {
            this.relations.push({
              source: persons.find(p => p.id === relation.s_pid),
              target: persons.find(p => p.id === relation.t_pid),
              relation
            });
          });
          console.log(this.relations);
        }
      }).catch(console.warn);
    },
    updateRelation(pid, relation) {
      if (this.person) {
        this.relations.map((_ref, i) => {
          let {
            target
          } = _ref;
          if (target.id === pid && target.id === pid) {
            this.relations[i].relation = relation;
          }
        });
      }
    },
    getContact(index) {
      const p = this.matchPersons[index];
      if (!p.me && !this.showPerson.me) {
        return;
      }
      this.contactLoading = true;
      relationService.getContact({
        from: p.me ? p.id : this.showPerson.id,
        target: p.me ? this.showPerson.id : p.id
      }).then(res => {
        if (res) {
          p.relation = res;
          this.updateRelation(p.id, res);
        }
      }).catch(console.warn).finally(() => this.contactLoading = false);
    },
    applyContact(p, r) {
      relationService.apply({
        target: p.me ? this.showPerson.id : p.id,
        from: this.person?.id
      }).then(res => {
        if (res) {
          if (!r) {
            p.relation = res;
          } else {
            this.updateRelation(p.id, res);
          }
        }
      }).catch(message.warn);
    },
    refuseApply(p, r) {
      console.log('拒绝申请 -> ', p);
      relationService.refuse({
        rid: r ? r.id : p.relation.id,
        from: this.person?.id
      }).then(res => {
        if (res) {
          if (!r) {
            p.relation = res;
          } else {
            this.updateRelation(p.id, res);
          }
        }
      }).catch(message.warn);
    },
    agreeApply(p, r) {
      relationService.agree({
        rid: r ? r.id : p.relation.id,
        from: this.person?.id
      }).then(res => {
        if (res) {
          if (!r) {
            p.relation = res;
          } else {
            this.updateRelation(p.id, res);
          }
        }
      }).catch(message.warn);
    },
    retryApply(p, r) {
      const relation = r ? r : p.relation;
      const refuseGender = relation.type === 'male_refuse' ? 1 : 2;
      this.confirm = {
        title: '提示',
        content: refuseGender === this.person.gender ? '申请交换联系方式' : '重新申请交换联系方式',
        buttonConfirm: '确认',
        showCancel: true,
        callback: () => this.applyContact(p, r)
      };
      this.confirmShow = true;
    },
    matchChange(e) {
      this.cy.nodes().map(n => {
        if (n.data('gender') === this.showPerson.gender) {
          return;
        }
        if (n.data('index') === e.activeIndex) {
          this.subHighlight(n);
        }
      });
    },
    setSwiper(swiper) {
      this.swiper = swiper;
    },
    viewReport(task) {
      if (this.highlightLine) {
        this.highlightLine.data('weight', 0.5);
      }
      let tid = task.id;
      if (!tid) {
        const s_pid = Math.min(task.s_pid, task.t_pid);
        const t_pid = Math.max(task.s_pid, task.t_pid);
        this.tasks.map(t => {
          if (t.type === task.type && s_pid === t.s_pid && t_pid === t.t_pid) {
            tid = t.id;
          }
        });
      }
      this.cy.edges().map(l => {
        if (parseInt(l.data('id')) === tid) {
          this.highlightLine = l;
        }
      });
      this.highlightLine.data('weight', 1);
      this.highlightLine.style();
      if (this.highlightLine.data('status') !== 'completed') {
        message.warning('配对未完成，请稍后再试');
      } else {
        this.viewReportId = String(tid);
      }
    },
    backDetail() {
      if (this.highlightLine) {
        this.highlightLine.data('weight', 0.5);
        this.highlightLine.style();
        this.highlightLine = null;
      }
      this.viewReportId = 0;
      document.title = 'AI牵红绳活动配对';
    },
    relationClick(tPid, sPid) {
      if (!this.person && !sPid) {
        return;
      }
      const s_pid = sPid ? String(sPid) : String(this.person.id);
      let source, target;
      this.cy.nodes().map(n => {
        if (n.data('id') === s_pid) {
          source = n;
        } else if (n.data('id') === String(tPid)) {
          target = n;
        }
      });
      this.highlight(source, target);
    },
    subHighlight(node) {
      const nid = parseInt(node.data('id'));
      if (nid === this.showPerson?.id) {
        return;
      }
      this.getContact(node.data('index'));
      if (this.lastSubHighligth) {
        this.lastSubHighligth.data('weight', 0.5);
      }
      this.lastSubHighligth = node;
      node.data('weight', 1);
      this.cy.nodes().map(n => {
        if (n.data('id') === node.data('id') || parseInt(n.data('id')) === this.showPerson.id) {
          n.data('weight', 1);
        } else {
          n.data('weight', 0.5);
        }
      });
      if (this.swiper) {
        this.swiper.slideTo(node.data('index'));
      }
    },
    unSubHighlight() {
      if (this.lastSubHighligth) {
        this.lastSubHighligth.data('weight', 0.5);
        this.lastSubHighligth = null;
      }
      this.cy.edges().map(e => {
        if (this.hn || e.data('s_pid') === this.person?.id || e.data('t_pid') === this.person?.id) {
          e.data('weight', 0.5);
        } else {
          e.data('weight', 0.1);
        }
      });
    },
    highlight(node, sub) {
      if (this.showPerson && this.showPerson.id === parseInt(node.data('id'))) {
        return Promise.resolve();
      }
      if (this.highlightInProgress) {
        return Promise.resolve();
      }
      this.highlightInProgress = true;
      this.hasHighlight = true;
      const showPersonPanel = () => {
        const nid = parseInt(node.data('id'));
        const mineId = this.person?.id;
        // 初始化嘉宾面板数据
        if (node.data('gender') === 1) {
          this.men.map((man, index) => {
            if (man.id === nid) {
              this.showPerson = {
                ...man,
                namePrefix: `${index + 1}号男嘉宾`,
                name: man.id === mineId ? '我' : man.extra_string_1 || man.name,
                me: man.id === mineId
              };
            }
          });
          this.matchPersons = this.women.map((woman, index) => {
            return {
              ...woman,
              namePrefix: `${index + 1}号女嘉宾`,
              name: woman.id === mineId ? '我' : woman.extra_string_1 || woman.name,
              me: woman.id === mineId
            };
          });
        } else {
          this.women.map((woman, index) => {
            if (woman.id === nid) {
              this.showPerson = {
                ...woman,
                namePrefix: `${index + 1}号女嘉宾`,
                name: woman.id === mineId ? '我' : woman.extra_string_1 || woman.name,
                me: woman.id === mineId
              };
            }
          });
          this.matchPersons = this.men.map((man, index) => {
            return {
              ...man,
              namePrefix: `${index + 1}号男嘉宾`,
              name: man.id === mineId ? '我' : man.extra_string_1 || man.name,
              me: man.id === mineId
            };
          });
        }
        this.cy.nodes().map(n => {
          if (sub) {
            if (n.data('id') === sub.data('id')) {
              setTimeout(() => this.subHighlight(n), animationDuration + 100);
            }
          } else {
            if (n.data('index') === 0 && n.data('gender') !== node.data('gender')) {
              this.subHighlight(n);
            }
          }
        });
      };
      const allEles = this.cy.elements();
      const nhood = this.lastHighlighted = node.closedNeighborhood();
      const others = this.lastUnhighlighted = allEles.not(nhood);
      const showOverview = () => {
        this.showPerson = null;
        this.matchPersons = [];
        this.cy.batch(() => {
          allEles.removeClass('hidden');
          others.addClass('hidden');
          others.positions(n => {
            if (n.isNode()) {
              const pos = this.originalPoisitons[n.data('id')];
              return pos ? {
                ...pos
              } : {};
            } else {
              return {};
            }
          });
        });
        const layout = nhood.layout({
          name: 'preset',
          positions: n => {
            if (n?.isNode()) {
              const position = n.data('orgPos');
              return position ? {
                x: position.x,
                y: position.y
              } : {};
            } else {
              return {};
            }
          },
          fit: true,
          animate: true,
          animationDuration,
          animationEasing: easing,
          padding: layoutPadding
        });
        layout.run();
        return layout.promiseOn('layoutstop');
      };
      const runLayout = () => {
        const position = node.data('orgPos');
        const p = position ? {
          x: position.x,
          y: position.y
        } : {};
        const layout = nhood.layout({
          name: 'concentric',
          fit: true,
          animate: true,
          animationDuration,
          animationEasing: easing,
          boundingBox: {
            x1: p.x - 1,
            x2: p.x + 1,
            y1: p.y - 1,
            y2: p.y + 1
          },
          startAngle: Math.PI / 6,
          // 起始角度，3/2 π 表示从顶部开始
          clockwise: true,
          // 节点是否顺时针排列
          avoidOverlap: true,
          concentric: function (ele) {
            return ele.same(node) ? 2 : 1;
          },
          levelWidth: () => 1,
          padding: layoutPadding,
          spacingFactor: 2
        });
        const promise = layout.promiseOn('layoutstop');
        layout.run();
        return promise;
      };
      return Promise.resolve().then(showOverview).then(() => delayPromise(animationDuration)).then(showPersonPanel).then(runLayout).then(() => {
        this.highlightInProgress = false;
      }).catch(console.warn);
    },
    unhighlight() {
      if (!this.hasHighlight) {
        return Promise.resolve();
      }
      const allEles = this.cy.elements();
      const allNodes = this.cy.nodes();
      this.cy.stop();
      allNodes.stop();
      this.showPerson = null;
      this.matchPersons = [];
      this.unSubHighlight();
      const nhood = this.lastHighlighted;
      const others = this.lastUnhighlighted;
      this.backDetail();
      this.cy.nodes().map(n => n.data('weight', 0.5));
      this.lastHighlighted = this.lastUnhighlighted = null;
      this.hasHighlight = false;
      const hideOthers = function () {
        others.addClass('hidden');
        return Promise.resolve();
      };
      const resetClasses = () => {
        this.cy.batch(function () {
          allEles.removeClass('hidden');
        });
        return Promise.resolve();
      };
      const animateToOrgPos = nhood => {
        return Promise.all(nhood.nodes().map(n => {
          return n.animation({
            position: this.originalPoisitons[n.data('id')],
            duration: animationDuration,
            easing: easing
          }).play().promise();
        }));
      };
      const restorePositions = () => {
        this.cy.batch(() => {
          others.nodes().positions(n => {
            return {
              ...n.data('orgPos')
            };
          });
        });
        return animateToOrgPos(nhood);
      };
      const animateToInitialView = () => {
        return this.cy.animate({
          fit: {
            eles: this.cy.elements(),
            padding: layoutPadding
          },
          pan: this.initialCenter,
          zoom: this.initialZoom,
          duration: this.animationDuration,
          easing: this.easing
        });
      };
      return Promise.resolve().then(hideOthers).then(restorePositions).then(resetClasses).then(animateToInitialView);
    },
    convertMember(person, length, women, men) {
      const nodes = [];
      const containerWidth = window.innerWidth; // 容器宽度
      const containerHeight = window.innerHeight; // 容器高度
      const xFemale = containerWidth * 0.25; // 女性节点的x坐标
      const xMale = containerWidth * 0.75; // 男性节点的x坐标
      const yStart = 0; // y坐标起始值
      const yStep = containerHeight / length; // y坐标步长

      const scale = this.landscape ? window.innerWidth / 960 : 1;
      const size = 80 * scale,
        fontSize = 18 * scale;
      const gap = size; // 节点之间的间距

      for (let i = 0; i < length; i++) {
        const femalePos = {
          x: xFemale,
          y: yStart + i * yStep
        };
        const malePos = {
          x: xMale,
          y: yStart + i * yStep
        };
        if (women[i]) {
          const me = person?.id === women[i].id;
          nodes.push({
            data: {
              id: women[i].id,
              index: i,
              name: me ? '我' : women[i].extra_string_1 || women[i].name,
              age: women[i].age,
              bg: me ? 'rgb(254, 206, 13)' : 'rgb(156, 100, 167)',
              color: me ? '#191447' : 'white',
              border: me ? 'rgb(254, 206, 13)' : 'rgb(156, 100, 167)',
              width: size + 'px',
              height: size + 'px',
              fontSize: fontSize + 'px',
              weight: me ? 1 : 0.5,
              gender: 2,
              me,
              orgPos: femalePos
            },
            position: femalePos
          });
        }
        if (men[i]) {
          const me = person?.id === men[i].id;
          nodes.push({
            data: {
              id: men[i].id,
              name: me ? '我' : men[i].extra_string_1 || men[i].name,
              age: men[i].age,
              index: i,
              bg: me ? 'rgb(254, 206, 13)' : 'rgb(98, 190, 157)',
              color: me ? '#191447' : 'white',
              border: me ? 'rgb(254, 206, 13)' : 'rgb(98, 190, 157)',
              width: size + 'px',
              height: size + 'px',
              fontSize: fontSize + 'px',
              weight: me ? 1 : 0.5,
              gender: 1,
              me,
              orgPos: malePos
            },
            position: malePos
          });
        }
      }

      // 计算边界
      const allPositions = nodes.map(node => {
        this.originalPoisitons[node.data.id] = {
          ...node.data.orgPos
        };
        return node.data.orgPos;
      });
      const minX = Math.min(...allPositions.map(pos => pos.x));
      const maxX = Math.max(...allPositions.map(pos => pos.x));
      const minY = Math.min(...allPositions.map(pos => pos.y));
      const maxY = Math.max(...allPositions.map(pos => pos.y));
      const bound = {
        x1: minX - gap,
        y1: minY - gap,
        x2: maxX + gap,
        y2: maxY + gap * 2
      };
      return {
        nodes,
        bound
      };
    },
    convertRelation(pid, tasks) {
      const scale = this.landscape ? window.innerWidth / 960 : 1;
      const width = 3 * scale,
        fontSize = 12 * scale;
      const edge = [];
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        let title = task.type === 'activity_survey' ? '价值观：' : '命理：';
        if (task.type === 'activity_survey') {
          title += task.total;
          if (task.total >= 1187.85) {
            title += '(非常高)';
          } else if (task.total >= 1071.05) {
            title += '(高)';
          } else if (task.total > 1012.65) {
            title += '(一般)';
          } else {
            title += '(低)';
          }
        } else {
          title += task.total ? task.total : '点击查看';
          if (task.total > 90) {
            title += '(非常高)';
          } else if (task.total > 70) {
            title += '(高)';
          } else if (task.total > 60) {
            title += '(一般)';
          } else if (task.total !== 0) {
            title += '(低)';
          }
        }
        const mine = pid === task.s_pid || pid === task.t_pid;
        edge.push({
          data: {
            id: task.id,
            name: title,
            s_pid: task.s_pid,
            t_pid: task.t_pid,
            source: task.type === 'activity_survey' ? task.s_pid : task.t_pid,
            target: task.type === 'activity_survey' ? task.t_pid : task.s_pid,
            weight: this.hn || mine ? 0.5 : 0.1,
            width,
            fontSize: fontSize + 'px',
            line: task.type === 'activity_survey' ? 'rgb(255, 69 ,0)' : '#626aef',
            color: 'white',
            type: task.type,
            status: task.status,
            style: 'solid'
          }
        });
      }
      return edge;
    },
    async init() {
      this.loading = ElLoading.service({
        lock: true,
        text: '正在准备房间',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        if (!this.activity) {
          await this.initActivity({
            aid: this.aid,
            pid: this.pid,
            hid: this.hid
          });
          this.activity = this.getActivity();
          if (!this.activity) {
            return;
          }
          this.reportTypes = this.activity.report_types;
          this.person = this.getPerson();
          this.hn = this.getHn();
          this.men = this.getMen();
          this.women = this.getWomen();
          this.tasks = this.getTasks();
        }
        if (this.person) {
          this.loadRelation();
        }
        if (this.hn) {
          this.loadAllRelation();
        }
        const reports = this.activity.report_types.filter(item => item);
        const {
          bound,
          nodes
        } = this.convertMember(this.person, Math.max(this.activity.man_ids.length, this.activity.women_ids.length), this.women, this.men);
        this.cy = cytoscape({
          container: document.getElementById('cy'),
          // 容器元素
          elements: [...nodes, ...this.convertRelation(this.person?.id, this.tasks)],
          style: [
          // 节点样式
          {
            selector: 'node',
            style: {
              'label': 'data(name)',
              'background-color': 'data(bg)',
              'text-valign': 'center',
              'text-halign': 'center',
              'color': 'data(color)',
              'font-size': 'data(fontSize)',
              'opacity': 'data(weight)',
              'width': 'data(width)',
              'height': 'data(height)',
              'font-weight': 'bold',
              'border-color': 'data(border)',
              'border-width': '2px',
              'text-outline-width': 1,
              'text-outline-color': 'data(border)',
              'text-outline-opacity': 1,
              'overlay-color': 'data(border)'
            }
          },
          // 边样式
          {
            selector: 'edge',
            style: {
              'label': 'data(name)',
              'line-color': 'data(line)',
              'line-style': 'data(style)',
              'line-opacity': 'data(weight)',
              'text-opacity': 'data(weight)',
              'curve-style': reports.length === 1 ? 'straight' : 'round-segments',
              'color': 'data(color)',
              'width': 'data(width)',
              'font-size': 'data(fontSize)'
            }
          }, {
            selector: '.hidden',
            style: {
              'display': 'none'
            }
          }],
          layout: {
            name: 'preset'
          },
          selectionType: 'single',
          maxZoom: 5,
          minZoom: 0.3,
          userPanningEnabled: true,
          boxSelectionEnabled: false
        });
        this.bound = bound;
        this.cy.fit(bound);
        this.cy.center();
        // 监听空白处点击事件
        this.cy.on('tap', evt => {
          if (this.highlightInProgress) {
            return;
          }
          if (evt.target.isNode && evt.target.isNode()) {
            if (!this.showPerson) {
              this.highlight(evt.target);
            } else {
              this.subHighlight(evt.target);
            }
          } else if (evt.target.isEdge && evt.target.isEdge()) {
            const {
              id,
              status,
              s_pid,
              t_pid
            } = evt.target.data();
            if (status !== 'completed') {
              message.warning('配对未完成，请稍等片刻');
            } else {
              if (this.hn || this.person?.id === s_pid || this.person?.id === t_pid) {
                let source, target;
                this.cy.nodes().map(n => {
                  if (s_pid === parseInt(n.data('id'))) {
                    source = n;
                  } else if (t_pid === parseInt(n.data('id'))) {
                    target = n;
                  }
                });
                if (!this.showPerson) {
                  if (this.hn || source.data('me')) {
                    this.highlight(source, target);
                  } else {
                    this.highlight(target, source);
                  }
                } else {
                  if (this.showPerson.id === parseInt(target.data('id'))) {
                    this.subHighlight(source);
                  } else {
                    this.subHighlight(target);
                  }
                }
                this.viewReport({
                  id: parseInt(id)
                });
              } else {
                message.warning('无法查看他人的报告');
              }
            }
          } else {
            // 执行点击空白处的逻辑
            this.unhighlight();
          }
        });
      } finally {
        this.loading.close();
      }
    }
  }
};