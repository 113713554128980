import TeamDialog from '@/components/TeamDialog';
import AlertDialog from '@/components/AlertDialog';
import ClueDialog from '@/components/Reservation';
export default {
  name: 'HomePage',
  components: {
    TeamDialog,
    AlertDialog,
    ClueDialog
  },
  data: () => {
    return {
      room: require('@/assets/images/room.jpg'),
      room1: require('@/assets/images/room1.jpg'),
      room2: require('@/assets/images/room2.jpg'),
      room3: require('@/assets/images/room3.jpg'),
      room4: require('@/assets/images/room4.jpg'),
      sence1: require('@/assets/images/sence1.jpg'),
      teamShow: false,
      clueShow: false,
      confirmShow: false,
      confirm: {
        title: '',
        content: '',
        buttonConfirm: '',
        showCancel: false
      }
    };
  },
  computed: {
    landscape() {
      console.log('landscape home -> ', this.$store.state.landscape);
      return this.$store.state.landscape;
    }
  },
  methods: {
    makeReservation() {
      this.clueShow = true;
    },
    makeTeam() {
      this.teamShow = true;
    },
    success(e, type) {
      console.log(e, '=======', type);
      if (type === 'team' && e.result) {
        this.confirm.title = '创建成功';
        this.confirm.content = '感谢您的耐心填写，随后我们将有工作人员联系您，并协助您快速开始使用。';
        this.confirm.buttonConfirm = '好的';
        this.confirm.showCancel = false;
        this.confirmShow = true;
      }
      if (type === 'clue' && e.result) {
        this.confirm.title = '预约成功';
        this.confirm.content = '已收到您的预约，随后我们将有工作人员联系您，请保持电话畅通。';
        this.confirm.buttonConfirm = '好的';
        this.confirm.showCancel = false;
        this.confirmShow = true;
      }
    }
  }
};