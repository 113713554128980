import { initCloud } from '@wxcloud/cloud-sdk';
import axios from 'axios'
const server = new initCloud().Cloud({
  identityless: true,
  resourceAppid: 'wx1e81d9eeca55ba22',
  resourceEnv: 'prod-9gvc1avtfd790ccc'
})

server.init()
  .then(() => console.log('wx cloud 初始化成功'))
  .catch(err => console.warn('wx cloud 初始化错误: ', err))

export default class BaseService {

  constructor(path) {
    this.path = path
    this.server = server
    this.baseUrl = '/local'
  }

  getServerName() {
    return window.location.host.startsWith('survey') ? 'debug' : 'express-9cm2'
  }

  nGet(url) {
    // if (window.location.host.startsWith('192.168')) {
    //   return this.get(url)
    // }
    return new Promise((resolve, reject) => {
      this.server.callContainer({
        path: this.path + url,
        header: {
          'X-WX-SERVICE': this.getServerName()
        },
        method: 'GET',
        success: (res) => {
          if (res.statusCode !== 200) {
            reject(res)
          }
          const data = res.data
          // 请求内部判定失败
          if (data.err) {
            reject(data.err)
          }
          resolve(data)
        },
        fail: (err) => {
          reject(err)
        }
      })
    })
  }

  nPost(url, data) {
    // if (window.location.host.startsWith('192.168')) {
    //   return this.post(url, data)
    // }
    return new Promise((resolve, reject) => {
      this.server.callContainer({
        path: this.path + url,
        header: {
          'X-WX-SERVICE': this.getServerName()
        },
        data: JSON.stringify(data),
        method: 'POST',
        success: (res) => {
          if (res.statusCode !== 200) {
            reject(res)
          }
          const data = res.data
          // 请求内部判定失败
          if (data.err) {
            reject(data.err)
          }
          resolve(data)
        },
        fail: (err) => {
          reject(err)
        }
      })
    })
  }

  get(url, config, headers, permission = true) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.baseUrl + this.path}${url}`, Object.assign(config || {}, permission ? {
        headers
      } : {})).then(res => {
        if (res.status !== 200) {
          reject(res)
        }
        const data = res.data
        // 请求内部判定失败
        if (data.err) {
          reject(data.err)
        }
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  post(url, data, headers) {
    return new Promise((resolve, reject) => {
      axios.post(`${this.baseUrl + this.path}${url}`, data, { headers })
        .then(res => {
          if (res.status !== 200) {
            reject(res)
          }
          const data = res.data
          // 请求内部判定失败
          if (data.err) {
            reject(data.err)
          }
          resolve(data)
        }).catch(err => {
          reject(err)
        })
    })
  }
}
