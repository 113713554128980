import Base from './base.js'

class ActivityService extends Base {
  constructor() {
    super('/activity')
  }

  async detail(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/detail", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          alert('error:', JSON.stringify(error))
          reject(error)
        });
    })
  }

}

export default new ActivityService()
