import Base from './base.js'

class RelationService extends Base {
  constructor() {
    super('/relation')
  }

  async apply(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/apply", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async agree(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/agree", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async refuse(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/refuse", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async getContact({from, target}) {
    return new Promise((resolve, reject) => {
      this.nGet(`/contact?from=${from}&target=${target}`)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }

  async findRelations(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/list", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
  
  async findRelationsByActivity(data) {
    return new Promise((resolve, reject) => {
      this.nPost("/detail", data)
        .then(res => {
          if (!res.err) {
            resolve(res.result)
          } else {
            reject(res.err)
          }
        })
        .catch(error => {
          console.log('error', error)
          reject(error)
        });
    })
  }
}

export default new RelationService()
