import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $options.visible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.visible = $event),
    title: $props.title,
    "align-center": "",
    width: `${!$options.landscape ? '80%' : 600}`,
    "show-close": false
  }, {
    footer: _withCtx(() => [_createElementVNode("div", null, [$props.showCancel ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      onClick: _cache[0] || (_cache[0] = $event => this.visible = false)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.buttonCancel), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
      class: "confirm",
      onClick: $options.confirm,
      color: "#191447"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.buttonConfirm), 1)]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.content) + " ", 1)]),
    _: 1
  }, 8, ["modelValue", "title", "width"]);
}