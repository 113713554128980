const state = () => ({
  hnLists: []
});

const getters = {
  getHns: state => {
    console.log("getHns")
    return state.hnLists
  }
};

const actions = {
  fetchHns({ commit }) {
    console.log("fetchHns -> ", commit)
    // 模拟用户数据
    const hns = [
      { id: 1, name: "Alice" },
      { id: 2, name: "Bob" }
    ];
    commit('setHns', hns);
  }
};

const mutations = {
  setHns(state, hns) {
    console.log("setHns -> ", state)
    state.hnLists = hns;
  },
  addHn(state, hn) {
    console.log("add hn -> ", state)
    state.hnLists.push(hn)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
