import { ElMessage } from 'element-plus'

class Message {
  warning (message, options) {
    return ElMessage({
      message,
      type: 'warning',
      offset: options?.offset || 300
    })
  }

  success (message) {
    return ElMessage({
      message,
      type: 'success'
    })
  }

  error (message) {
    return ElMessage({
      message,
      type: 'error'
    })
  }
}

export default new Message()
