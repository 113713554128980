import service from '@/module/activity'

const state = () => ({
  activity: null,
  men: [],
  women: [],
  tasks: [],
  person: null,
  hn: null
});

const getters = {
  getPerson: state => {
    return state.person
  },
  getActivity: state => {
    return state.activity
  },
  getMen: state => {
    return state.men
  },
  getWomen: state => {
    return state.women
  },
  getTasks: state => {
    return state.tasks
  },
  getHn: state => {
    return state.hn
  }
};

const actions = {
  async initActivity({ commit }, payload) {
    const data = await service.detail(payload);
    if (data) {
      const { activity, person, hn, men, women, tasks } = data;
      commit('setActivity', activity);
      commit('setTasks', tasks);
      commit('setPerson', person);
      commit('setMen', men);
      commit('setWomen', women);
      commit('setHn', hn);
    }
  }
}

const mutations = {
  setTasks(state, tasks) {
    state.tasks = tasks
  },
  setActivity(state, activity) {
    state.activity = activity;
  },
  setPerson(state, person) {
    state.person = person;
  },
  setMen(state, men) {
    state.men = men;
  },
  setWomen(state, women) {
    state.women = women;
  },
  setHn(state, hn) {
    state.hn = hn;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
