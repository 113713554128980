import { debounce } from 'lodash';
import OfficialPage from '@/pages/OfficialPage.vue';
import ActivityRoom from '@/pages/activity/ActivityRoom.vue';
import LiveRoom from '@/pages/living/LiveRoom.vue';
import CustomClue from '@/pages/clue/CustomClue.vue';
import MatchScreen from '@/pages/match/Main.vue';
export default {
  name: 'App',
  components: {
    OfficialPage,
    ActivityRoom,
    MatchScreen,
    CustomClue,
    LiveRoom
  },
  data: () => {
    return {
      aid: 0,
      pid: 0,
      hid: 0,
      tid: 0,
      appid: '',
      token: '',
      initPage: ''
    };
  },
  methods: {
    handleResize: debounce(function () {
      this.$store.commit('setDevice', window.innerWidth > window.innerHeight);
    }, 200) // 延迟200ms
  },
  mounted() {
    document.documentElement.className = 'dark'; //获取html根元素
    this.$store.commit('setDevice', window.innerWidth > window.innerHeight);
    window.addEventListener('resize', this.handleResize);
    let params = window.location.href.split('?');
    params = params.length > 1 ? params[1].split('&') : [];
    const kv = {};
    for (let i = 0; i < params.length; i++) {
      if (!params[i]) {
        continue;
      }
      const param = params[i].split('=');
      if (param.length !== 2) {
        continue;
      }
      kv[param[0]] = param[1];
    }
    const {
      page
    } = kv;
    this.initPage = page;
    try {
      if (kv.aid) {
        this.aid = parseInt(kv.aid);
      }
      if (kv.hid) {
        this.hid = parseInt(kv.hid);
      }
      if (kv.pid) {
        this.pid = parseInt(kv.pid);
      }
      if (kv.tid) {
        this.tid = parseInt(kv.tid);
      }
      if (kv.appid) {
        this.appid = kv.appid;
      }
      if (kv.token) {
        this.token = kv.token;
      }
      document.body.classList.add('dark-theme');
    } catch (error) {
      console.log('error -> ', error);
    }
  }
};