export default {
  name: 'OfficialPage',
  computed: {
    landscape() {
      return this.$store.state.landscape;
    },
    path() {
      return this.$route.query;
    }
  },
  watch: {
    path(newValue) {
      if (newValue?.page === 'md') {
        const id = newValue.id;
        console.log('md watch -> ', id);
        this.$router.replace({
          path: '/md',
          query: {
            id
          }
        });
      }
    }
  },
  mounted() {
    const page = this.$route.query?.page;
    if (page === 'md') {
      const id = this.$route.query.id;
      console.log('md mounted -> ', id);
      this.$router.replace({
        path: '/md',
        query: {
          id
        }
      });
    }
  }
};